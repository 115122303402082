import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAuthService } from './api-auth.service';

@Injectable({
	providedIn: 'root'
})
export class GoogleAuthService {
	constructor(
		private _socialAuthService: SocialAuthService,
		private _router: Router,
		private apiAuth: ApiAuthService
	) {}

	signIn() {
		this._socialAuthService.authState.subscribe({
			next: (user) => {
				if (user) {
					localStorage.setItem('userGoogle', JSON.stringify(user));
					this.singInWorkflow(user);
				} else {
				}
			},
			error: (error) => {
			}
		});
	}

	private singInWorkflow(user: SocialUser) {
		this.apiAuth.signInGoogle(user.idToken).subscribe({
			next: (response: any) => {
				localStorage.setItem('user', JSON.stringify(response.response));
				this._router.navigate(['/']);
			},
			error: (error: any) => {
			}
		});
	}

	signOut() {
		localStorage.clear();
		sessionStorage.clear();
		this._router.navigate(['/login']).then((result) => {
			// FIXME: Verificar a fondo el error por que se inicia sesión automaticamente
			window.location.reload();
		});
	}
}
