import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserAuthService } from './user-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService {
  private urlApi: string = environment.apiUrl + 'auth';

  constructor(
    private http: HttpClient,
    private userAuthService: UserAuthService,
  ) {}

  signInGoogle(token: string): Observable<any> {
    let direction = this.urlApi + '/google';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'g-auth-token': token,
      }),
    };

    return this.http.post(direction, {}, options);
  }

  signOut(): Observable<any> {
    let direction = this.urlApi + '/sign-out';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.userAuthService.getToken(),
      }),
    };

    return this.http.post(direction, {}, options);
  }

  signIn(username: string, password: string, token: string): Observable<any> {
    let direction = this.urlApi + '/sap';

    return this.http.post(direction, { username: username, password: password, token: token });
  }
}
